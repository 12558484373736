import React from "react"
import styled from "styled-components"
import { Position, StyledContainer, TextBlockWrapper } from "."
import Button from "../../base/button"
import WpContent from "./wpContent"

interface IProps {
  content: Maybe<string>
  bgColor: Maybe<string>
  btnText: Maybe<string>
  btnColor: Maybe<string>
  btnAlignment: Maybe<string>
  btnLink: Maybe<string>
  maxWidth?: MuiKey
  position?: Position
  alignTop?: Maybe<boolean>
}

const PositionButton = styled.div<{ dataAlignment: Maybe<string> }>`
  display: flex;
  align-items: center;
  justify-content: ${({ dataAlignment }) => {
    switch (dataAlignment) {
      case "center":
        return "center"
      case "right":
        return "flex-end"
      case "left":
      default:
        return "flex-start"
    }
  }};
`

const CtaTextBlock: React.FC<IProps> = ({
  content,
  bgColor,
  btnText,
  btnColor,
  btnAlignment,
  btnLink,
  maxWidth = "md",
  position = "center",
  alignTop,
}) => {
  return (
    <TextBlockWrapper bg={String(bgColor)} alignTop={alignTop}>
      <StyledContainer maxWidth={maxWidth} position={position} disableGutters>
        <WpContent dangerouslySetInnerHTML={{ __html: String(content) }} />
        <PositionButton dataAlignment={btnAlignment}>
          <Button color={String(btnColor)} link={btnLink}>
            {btnText}
          </Button>
        </PositionButton>
      </StyledContainer>
    </TextBlockWrapper>
  )
}

export default CtaTextBlock
